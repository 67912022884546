import { useCallback } from "react";
import store from "./redux/store";
import { useLazyFindVoucherV2Query } from "./vouchersV2/api/vouchersApi.tsx";
import { VoucherV2, voucherV2Added } from "./vouchersV2/vouchersV2Slice.tsx";
import { canAddNewVoucher } from "./vouchersV2/canAddNewVoucher.ts";
import { BlockUserInput } from "@jamezz/react-components";

export default function useVouchers({ withLoading = true }: { withLoading: boolean } = { withLoading: true }) {
  const [trigger, result] = useLazyFindVoucherV2Query();
  BlockUserInput.useBlockUserInput(result.isFetching && withLoading);

  const checkVoucherCodeV2 = useCallback(
    (voucherCode: string) => {
      return new Promise<VoucherV2>((resolve, reject) => {
        trigger({ code: voucherCode, vouchersV2: store.getState().vouchersV2.vouchers })
          .then((response) => {
            if (response.isSuccess) {
              const result = canAddNewVoucher(store.getState().vouchersV2.vouchers, response.data);
              if (!result.isError) {
                resolve(response.data);
                store.dispatch(voucherV2Added(response.data));
              } else {
                reject({ result: { message: result.message } });
              }
            } else {
              if (
                response.error &&
                "data" in response.error &&
                response.error.data &&
                typeof response.error.data == "object" &&
                "message" in response.error.data
              ) {
                reject({ result: { message: response.error?.data?.message ?? "Voucher error" } });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    [trigger]
  );

  return { checkVoucherCode: checkVoucherCodeV2 };
}
