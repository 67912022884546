import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import useCurrency, { CurrencyLocation } from "../../../../global/utils/useCurrency";
import clsx from "clsx";
import { useAppSelector } from "../../../../global/utils/redux/store";
import { selectArticleCampaignsMap } from "../../../../global/utils/redux/selectors/selectArticleCampaignsMap";

interface Props {
  articleId: string;
  localPrice: number | null;
  price: number;
  vaprice: number;
}

export default function PriceWrapper(props: Props) {
  const parseCurrency = useCurrency({ location: CurrencyLocation.Menu });
  const articleCampaignsMap = useAppSelector(selectArticleCampaignsMap);
  const isCampaignArticle = articleCampaignsMap[props.articleId]?.length > 0;
  const background = useAppSelector(
    (state) =>
      state.global.salesarea?.media_urls?.extra_settings__kioskv5_background_of_price_label?.[0]?.conversions?.optimized
        ?.url
  );
  return props.price > 0 || props.vaprice > 0 ? (
    <Box
      className={"JS-ArticleContent-PriceWrapper"}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: background ? "primary.contrastText" : null,

        background: background ? `url('${background}')` : null,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      {props.localPrice != null ? (
        <>
          <Typography className={"JS-ArticleContent-LocalPrice"} sx={{ textDecoration: "line-through", fontSize: 18 }}>
            {parseCurrency(props.localPrice)}
          </Typography>
        </>
      ) : null}
      <Typography
        sx={{
          fontWeight: 800,
          whiteSpace: "nowrap",
          fontSize: 25,
          marginLeft: props.localPrice != null ? 1 : null,
          // color: isCampaignArticle ? "red" : null,
        }}
        className={clsx(isCampaignArticle ? "JS-ArticleContent-Price-IsCampaign" : null, "JS-ArticleContent-Price")}
      >
        {parseCurrency(props.vaprice > 0 ? props.vaprice : props.price)}
      </Typography>
    </Box>
  ) : null;
}
