import { ButtonBase, IconButton, Typography } from "@mui/material";

import OrderOptionGroup, { isMoreValid } from "../../../../global/utils/models/order/OrderOptionGroup";
import OrderArticle from "../../../../global/utils/models/order/OrderArticle";
import useCurrency, { CurrencyLocation } from "../../../../global/utils/useCurrency";
import Image from "../../../../global/components/Image";
import OptionItemSwitch from "./OptionItemSwitch";
import clsx from "clsx";
import Box from "@mui/material/Box";
import MenuTag, { useHasFilteredAllergens } from "../../../../global/utils/models/menu/MenuTag";
import MenuTagView from "../../../../qr/components/MenuTagView";
import useMenuTagsForArticle from "../../../../global/utils/hooks/useMenuTagsForArticle";
import { useOptionTranslations } from "../../../../global/utils/translations/useOptionTranslations";
import { getArticleName } from "../../../../global/utils/models/menu/Article";
import _ from "lodash";
import { useIntl } from "react-intl";
import { Info } from "@mui/icons-material";
import JTooltip from "../../../components/JamezzMUI/JTooltip";
import useActiveMenuTags from "../../../../global/utils/menu/useActiveMenuTags";
import { optionItemMenuTagsStyles } from "../OptionGroups/OptionGroupCountable";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider";
import ArticleInformationDialog from "../../../components/ArticleInformationDialog/ArticleInformationDialog";
import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { selectAppLanguage } from "../../../components/LanguageSelector/useLanguage.ts";
import useNestedOptionGroupsDialog from "../NestedOptions/useNestedOptionGroupsDialog.tsx";

interface Props {
  orderArticle: OrderArticle;
  orderOptionGroup: OrderOptionGroup;
  onChange: (orderArticle: OrderArticle) => void;

  columnIndex: number;
  rowIndex: number;
}

export default function OptionItemOptional(props: Props) {
  const { translate } = useOptionTranslations();
  const checked = props.orderArticle.count > 0;
  const menuTags = useMenuTagsForArticle(props.orderArticle.article);
  const lang = useAppSelector(selectAppLanguage);
  const { openDialog } = useDialog();
  const toCurrency = useCurrency({ location: CurrencyLocation.ArticleDetail, prefixWithSign: true });

  const isNotAvailable = props.orderArticle.article.isNotAvailable || props.orderArticle.article.isOutOfStock;
  const intl = useIntl();

  const activeMenuTags = useActiveMenuTags(props.orderArticle.article);
  const hasFilteredAllergen = useHasFilteredAllergens(activeMenuTags);

  const openNestedOptionGroups = useNestedOptionGroupsDialog();

  return (
    <>
      <JTooltip title={isNotAvailable ? intl.formatMessage({ id: "OptionItem.notAvailable" }) : ""}>
        <ButtonBase
          className={clsx(
            checked && "JS-OptionItemOptional-Button-Selected",
            "JS-OptionItemOptional-Root",
            "JS-OptionItemGeneric-Root"
          )}
          sx={{
            borderRadius: 4,
            marginTop: props.rowIndex != 0 ? 2.5 : undefined,
            paddingTop: 2,
            backgroundColor: checked ? "primary.light" : null,
            color: checked ? "white" : null,
            // border: !checked ? "1px solid lightgrey" : undefined,
            opacity: isNotAvailable ? "0.5" : "auto",
            cursor: isNotAvailable ? "default" : "auto",
            gridArea: `${props.rowIndex * 4 + 1} / ${props.columnIndex + 1} / ${props.rowIndex * 4 + 6} / ${
              props.columnIndex + 2
            }`,
            filter: hasFilteredAllergen ? "opacity(0.6)" : "",
            boxShadow: "0 1px 10px 0px rgba(0,0,0,0.2)",
          }}
          disableTouchRipple={isNotAvailable}
          onClick={async () => {
            if (isNotAvailable) return;
            if (isMoreValid(props.orderOptionGroup, props.orderArticle.article, checked ? -1 : 1)) {
              const copy = JSON.parse(JSON.stringify(props.orderArticle)) as OrderArticle;
              copy.count = checked ? 0 : 1;

              const orderArticle = await openNestedOptionGroups(copy);
              if (orderArticle) {
                props.onChange(orderArticle);
              }
            }
          }}
        ></ButtonBase>
      </JTooltip>
      {props.orderArticle.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset ? (
        <Box
          sx={{
            height: "200px",
            pointerEvents: "none",
            zIndex: 1,
            width: 1,
            gridArea: `${props.rowIndex * 4 + 1} / ${props.columnIndex + 1} / ${props.rowIndex * 4 + 2} / ${
              props.columnIndex + 2
            }`,
            // marginTop: 2.5,
            overflow: "hidden",
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            filter: hasFilteredAllergen ? "opacity(0.6)" : "",
          }}
        >
          <Image
            srcSet={props.orderArticle.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            className={"JS-OptionItemOptional-Image"}
          />
        </Box>
      ) : null}
      {props.orderArticle.article.description ? (
        <Box
          sx={{
            height: "200px",
            pointerEvents: "none",
            zIndex: 1,
            width: 1,
            gridArea: `${props.rowIndex * 4 + 1} / ${props.columnIndex + 1} / ${props.rowIndex * 4 + 2} / ${
              props.columnIndex + 2
            }`,
            display: "inline-flex",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            filter: hasFilteredAllergen ? "opacity(0.6)" : "",
          }}
        >
          <IconButton
            sx={{ pointerEvents: "all" }}
            onClick={(e) => {
              e.stopPropagation();
              openDialog({
                children: <ArticleInformationDialog article={props.orderArticle.article} />,
              });
            }}
          >
            <Info />
          </IconButton>
        </Box>
      ) : null}
      <Box
        sx={{
          pointerEvents: "none",
          zIndex: 1,
          gridArea: `${props.rowIndex * 4 + 2} / ${props.columnIndex + 1} / ${props.rowIndex * 4 + 3} / ${
            props.columnIndex + 2
          }`,
          textAlign: "center",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          filter: hasFilteredAllergen ? "opacity(0.6)" : "",
          wordBreak: "break-word",
        }}
      >
        <Typography
          className={clsx("JS-OptionItemOptional-Text", checked ? "JS-OptionItemOptional-Text-selected" : null)}
          variant={"subtitle2"}
          sx={{ color: checked ? "primary.contrastText" : undefined, px: 1 }}
        >
          {!_.isEmpty(props.orderArticle.article.translations)
            ? getArticleName(props.orderArticle.article, lang)
            : translate(props.orderArticle.article.name)}
          {isNotAvailable ? (
            <>
              <br />
              <Info fontSize="small" />
            </>
          ) : null}
        </Typography>
      </Box>

      {!isNotAvailable ? (
        <OptionItemSwitch
          selected={checked && false}
          sx={{
            pointerEvents: "none",
            zIndex: 1,
            gridArea: `${props.rowIndex * 4 + 3} / ${props.columnIndex + 1} / ${props.rowIndex * 4 + 4} / ${
              props.columnIndex + 2
            }`,
            width: "100%",
            // borderRadius: 8,
            // minHeight: 48,
            borderWidth: 0,
            borderColor: checked ? "transparent" : "grey.500",
            borderStyle: "solid",
            visibility: props.orderArticle.article.price === 0 && "collapse",
            filter: hasFilteredAllergen ? "opacity(0.6)" : "",
          }}
          className={clsx("JS-OptionItemOptional-Price", checked ? "JS-OptionItemOptional-Price-selected" : null)}
        >
          {props.orderArticle.article.price !== 0 ? (
            <Typography
              variant={"subtitle2"}
              sx={{
                fontSize: 24,
                fontStyle: "italic",
                opacity: 0.6,
                color: checked ? "primary.contrastText" : undefined,
              }}
            >
              {toCurrency(props.orderArticle.article.price)}
            </Typography>
          ) : null}
        </OptionItemSwitch>
      ) : null}

      {menuTags?.length > 0 ? (
        <Box
          sx={{
            gridArea: `${props.rowIndex * 4 + 4} / ${props.columnIndex + 1} / ${props.rowIndex * 4 + 5} / ${
              props.columnIndex + 2
            }`,
            ...optionItemMenuTagsStyles(),
            filter: hasFilteredAllergen ? "opacity(0.6)" : "",
          }}
        >
          {menuTags.map((tag: MenuTag) => {
            return (
              <MenuTagView
                key={tag.id}
                menuTag={tag}
                width={48}
                margin={"4px"}
                clickable={false}
                placement={"top-end"}
              />
            );
          })}
        </Box>
      ) : null}
    </>
  );
}
