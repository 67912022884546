import { rtkQueryApi } from "./rtkQueryApi";
import { JamezzApiResponse } from "../../../../types/shared/api/common_types";
import Articlegroup, { getColumnCount, getMinWidthPerArticle, getRowCount } from "../../models/menu/Articlegroup";
import parseApiTimeTable from "../../api/parseApis/parseApiTimeTable";
import { OptionTranslations } from "../../models/menu/Article";
import EanCode, { OracleEanCode } from "../../models/menu/EanCode";
import store from "../store";
import {
  menuUpdatedAt,
  setMenuDataArticlegroups,
  setMenuDataArticleTraits,
  setMenuDataCampaignProducts,
  setMenuDataCampaigns,
  setMenuDataCategoryItems,
  setMenuDataMenukaartProducts,
  setMenuDataOptionGroups,
  setMenuDataProducts,
  setMenuDataTags,
  timeSchedulesReceived,
  upsellsReceived,
} from "../menuDataSlice";

const menuDataEndpoints = rtkQueryApi.enhanceEndpoints({});

export interface MenukaartProductResponse {
  product_id: number;
  menukaart_id: number;
}
export interface CategoryItemResponse {
  category_id: number;
  category_item_id: number;
  category_item_type: "campaign" | "category" | "product";
  sort_key: number;
}
export interface CampaignProductResponse {
  campaign_id: string;
  product_id: string;
}

export interface BaseCampaignResponse {
  id: string;
  apiId1?: string;
  media_urls: { [key: string]: MediaUrl[] };
  type: string;
  name: string;
  start_date_time: string;
  end_date_time: string;
  custom_data?: string;
}
export type XPerGroupForYCampaignResponse = BaseCampaignResponse & {
  type: "X_PER_GROUP_FOR_Y";
  x_per_group_for_y: { name: string; numberOfProducts: number; productIds: [] }[];
};
export type CampaignResponse = BaseCampaignResponse | XPerGroupForYCampaignResponse;

export interface UpsellResponse {
  activated: boolean;
  flows: FlowResponse[];
  id: number;
  name: string;
  upsell_variant: "upsell" | unknown;
  trigger_products: { id: number }[];
  trigger_menus: { id: number }[];
}

export interface FlowResponse {
  id: number;
  upsell_id: number;
  time_schedule: [{ id: number }];
  pages: PageResponse[];
}

export interface PageResponse {
  id: number;
  options: unknown;
  products: { id: number }[];
  sort_key: number;
  upsell_page_variant: "SUGGESTION" | unknown;
  name: CustomizableTextResponse[];
  description: CustomizableTextResponse[];
}

export interface TimeScheduleResponse {
  id: number;
  is_active: boolean;
  name: string;
  valid_from: string;
  valid_until: string | null;
  opening_hours: {
    rawData: {
      monday: string[];
      tuesday: string[];
      wednesday: string[];
      thursday: string[];
      friday: string[];
      saturday: string[];
      sunday: string[];
    };
  };
}

export interface CustomizableTextResponse {
  created_at: string;
  id: number;
  language_code: string;
  relation_name: string;
  text: string;
  translatable_id: number;
  translatable_type: string;
  translation_key: string;
  updated_at: string;
}

export interface MediaUrl {
  conversions: { [key: string]: { url: string; srcset?: string } };
  original: string;
  id: number;
  order_column: number;
  mime_type: string;
}
export interface ArticlegroupResponse {
  id: number;
  apiId1?: string;
  bigdescr?: string;
  blocked: number;
  categorie: string;
  category: string;
  columnCount: number;
  customizable_texts: CustomizableTextResponse[];
  disableOrdering: number;
  media_urls: { [key: string]: MediaUrl[] };
  naam: string;
  parent_id?: number;
  sortkey?: number;
  settings?: string;
  showInCategoryMenu: number;
  timeTable?: [];
  titleMenuDescr: string;
  titleMenuItem: number;
  translations: string;
}

export interface OptionGroupProductGroupResponse {
  menukaart_id: number;
  option_group_id: number;
  sort_key: number | null;
}
export interface OptionGroupProductResponse {
  product_id: number;
  option_group_id: number;
  sort_key: number | null;
}

export interface ProductOptionGroupResponse {
  product_id: number;
  option_group_id: number;
  sort_key: number | null;
  min_count: number | null;
  max_count: number | null;
}

export interface ProductResponse {
  id: string;
  allergenen?: string;
  apiId1?: string;
  arrangement_weight?: number;
  printName?: string;
  bgColor?: string;
  blocked?: boolean;
  custom_data?: string;
  customizable_texts: CustomizableTextResponse[];
  depositId?: string;
  ean?: string;
  eans?: (EanCode | OracleEanCode)[];
  fillerProduct?: string;
  freeOptions?: string;
  freeOptions2?: string;
  freeOptions3?: string;
  freeOptionsRequired?: string;
  freeOptionsRequired2?: string;
  freeOptionsRequired3?: string;
  freeOptionsRequiredTitle?: string;
  freeOptionsRequiredTitle2?: string;
  freeOptionsRequiredTitle3?: string;
  freeOptionsTitle?: string;
  freeOptionsTitle2?: string;
  freeOptionsTitle3?: string;
  media_urls: { [key: string]: MediaUrl[] };
  minCount?: number;
  maxOrder: number;
  naam: string;
  not_available: boolean;
  omschrijving?: string;
  opties?: unknown[];
  price: number;
  prices?: unknown[];
  prijs: number;
  prijsDelivery?: string;
  prijsEatIn?: string;
  prijsTakeaway?: string;
  product_option_groups?: ProductOptionGroupResponse[];
  proppos_product?: { proppos_product_id: string };
  requireAge: number;
  settings?: string;
  sortkey?: number;
  stock: number;
  tags?: string;
  timeTable?: string;
  translations?: string;
  upsellMenu?: string;
  vap?: string;

  defaultCount?: number;
  default?: boolean;

  product_tags: { id: string; tag_id: string; product_id: string }[];
}

export interface OptionGroupResponse {
  countable: boolean;
  custom_data?: unknown;
  id: string;
  max_count: number;
  min_count: number;
  name: string;
  show_max_number_of_items: number;
  skip: number;
  sort_key: number;
  option_group_products: OptionGroupProductResponse[];
  option_group_product_groups: OptionGroupProductGroupResponse[];
  translations: OptionTranslations;
  default_counts: { product_ids: { [product_id: number]: { count: number } } };
}

export interface TagResponse {
  id: string;
  active: boolean;
  show_tag: boolean;
  tagName: string;
  tagType: string;
  tag_type: "ALLERGEN" | "PREFERENCE" | "BRAND";
  translations: OptionTranslations;
  media_urls: { [key: string]: MediaUrl[] };
}

export interface ArticleTraitResponse {
  id: string;
  apiId: string;
  article_trait_values: ArticleTraitValueResponse[];
  img_uuids?: string[];
  is_active_in_jamezz: boolean;
  is_active_in_pos: boolean;
  name: string;
  type: string;
  use_as_quick_filter: boolean;
}

export interface ArticleTraitValueResponse {
  id: string;
  img_uuids?: string[];
  is_active_in_jamezz: boolean;
  is_active_in_pos: boolean;
  sort_key?: number;
  value: string;
}

export type FetchMenuDataResponse = {
  data: {
    menukaarts: ArticlegroupResponse[];
    menukaart_products: MenukaartProductResponse[];
    category_items: CategoryItemResponse[];
    option_groups: OptionGroupResponse[];
    products: ProductResponse[];
    tags: TagResponse[];
    article_traits: ArticleTraitResponse[];
    campaigns: CampaignResponse[];
    campaign_products: CampaignProductResponse[];
    time_schedules: TimeScheduleResponse[];
    upsells: UpsellResponse[];
  };
} & JamezzApiResponse;

export type FetchProductDataResponse = {
  data: {
    // menukaarts: ArticlegroupResponse[];
    // menukaart_products: MenukaartProductResponse[];
    // option_groups: OptionGroupResponse[];
    products: ProductResponse[];
    tags: TagResponse[];
    // article_traits: ArticleTraitResponse[];
    // campaigns: CampaignResponse[];
    // campaign_products: CampaignProductResponse[];
  };
} & JamezzApiResponse;

export type FetchMenuUpdatedAtResponse = {
  data: {
    menu_updated_at: string | null;
  };
} & JamezzApiResponse;

export const menuDataApi = menuDataEndpoints.injectEndpoints({
  endpoints: (builder) => ({
    fetchMenuData: builder.query<
      {
        menukaarts: Articlegroup[];
        menukaart_products: MenukaartProductResponse[];
        category_items: CategoryItemResponse[];
        option_groups: OptionGroupResponse[];
        products: ProductResponse[];
        tags: TagResponse[];
        article_traits: ArticleTraitResponse[];
        campaigns: CampaignResponse[];
        campaign_products: CampaignProductResponse[];
        time_schedules: TimeScheduleResponse[];
        upsells: UpsellResponse[];
      },
      void
    >({
      query: () => {
        return {
          url: `/v5_2/qr/data-fetch`,
          method: "GET",
        };
      },
      transformResponse: (baseQueryReturnValue: FetchMenuDataResponse) => {
        return {
          menukaarts: baseQueryReturnValue.data.menukaarts.map(parseArticlegroupResponseToArticlegroup),
          menukaart_products: baseQueryReturnValue.data.menukaart_products,
          category_items: baseQueryReturnValue.data.category_items,
          option_groups: baseQueryReturnValue.data.option_groups,
          products: baseQueryReturnValue.data.products,
          tags: baseQueryReturnValue.data.tags,
          article_traits: baseQueryReturnValue.data.article_traits,
          campaigns: baseQueryReturnValue.data.campaigns,
          campaign_products: baseQueryReturnValue.data.campaign_products,
          time_schedules: baseQueryReturnValue.data.time_schedules,
          upsells: baseQueryReturnValue.data.upsells,
        };
      },
      async onQueryStarted(query, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          store.dispatch(setMenuDataArticlegroups(data.menukaarts));
          store.dispatch(setMenuDataMenukaartProducts(data.menukaart_products));
          store.dispatch(setMenuDataCategoryItems(data.category_items));
          store.dispatch(setMenuDataOptionGroups(data.option_groups));
          store.dispatch(setMenuDataArticleTraits(data.article_traits));
          store.dispatch(setMenuDataCampaigns(data.campaigns));
          store.dispatch(setMenuDataCampaignProducts(data.campaign_products));
          store.dispatch(setMenuDataProducts(data.products));
          store.dispatch(setMenuDataTags(data.tags));
          store.dispatch(upsellsReceived(data.upsells));
          store.dispatch(timeSchedulesReceived(data.time_schedules));
        } catch (e) {
          console.log(e);
        }
      },
    }),
    fetchMenuUpdatedAt: builder.query<FetchMenuUpdatedAtResponse, { menukaartVestigingId: number }>({
      query: ({ menukaartVestigingId }) => {
        return {
          url: `/v5_2/qr/menu-updated-at?menukaart_vestiging_id=${menukaartVestigingId}`,
          method: "GET",
        };
      },
      async onQueryStarted(query, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          store.dispatch(menuUpdatedAt(data.data.menu_updated_at));
        } catch (e) {
          console.log(e);
        }
      },
    }),
    fetchProductsData: builder.query<
      {
        products: ProductResponse[];
        tags: TagResponse[];
      },
      { menukaartIds: string[] | undefined }
    >({
      query: ({ menukaartIds }) => {
        return {
          url: `/v5_2/qr/products-fetch`,
          method: "POST",
          body: { menukaartIds: menukaartIds },
        };
      },
      transformResponse: (baseQueryReturnValue: FetchProductDataResponse) => {
        return {
          products: baseQueryReturnValue.data.products,
          tags: baseQueryReturnValue.data.tags,
        };
      },
    }),
  }),
});

function parseArticlegroupResponseToArticlegroup(apiArticlegroup: ArticlegroupResponse): Articlegroup {
  let translations = {};
  if (apiArticlegroup.translations) {
    translations = JSON.parse(apiArticlegroup.translations);
  }

  return {
    id: String(apiArticlegroup.id),
    apiId1: apiArticlegroup.apiId1,
    name: apiArticlegroup.naam,
    showMaxNumberOfItems: getRowCount(apiArticlegroup),
    orderTimes: parseApiTimeTable(apiArticlegroup.timeTable),
    // imageSrcs: imageSrcs,
    alternativeName1: apiArticlegroup.titleMenuItem === 1 ? (apiArticlegroup.titleMenuDescr ?? "") : undefined,
    description: apiArticlegroup?.bigdescr ?? undefined,
    sortKey: apiArticlegroup.sortkey ? apiArticlegroup.sortkey : 0,
    // imageBanner,
    images: apiArticlegroup.media_urls?.imgid ?? [],
    imageBannerMediaUrl: apiArticlegroup.media_urls.imgidbanner?.[0] ?? undefined,
    numberOfColumns: getColumnCount(apiArticlegroup),
    minWidthPerArticle: getMinWidthPerArticle(apiArticlegroup),
    updatedAt: "",
    isVisibleInJamezz: !apiArticlegroup.blocked,
    showInCategoryMenu: apiArticlegroup.showInCategoryMenu != null ? Boolean(apiArticlegroup.showInCategoryMenu) : true,
    settings: apiArticlegroup.settings,
    parentId: apiArticlegroup.parent_id ?? null,
    translations,
    disableOrdering: !!apiArticlegroup.disableOrdering,
    category: apiArticlegroup.categorie,
    customizable_texts: apiArticlegroup.customizable_texts,
    blocked: !!apiArticlegroup.blocked,
  };
}

export const {
  useFetchMenuDataQuery,
  useLazyFetchMenuDataQuery,

  useFetchMenuUpdatedAtQuery,
} = menuDataApi;
