import Box from "@mui/material/Box";
import { useMemo, useRef, useState } from "react";
import { Chip, Collapse, Stack, Typography } from "@mui/material";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency";
import OrderArticle, { getTotalPrice } from "../../../global/utils/models/order/OrderArticle";
import { getArticleDescription, getArticleName } from "../../../global/utils/models/menu/Article";
import MenuTag from "../../../global/utils/models/menu/MenuTag";
import CloseDialogButton from "../../../kiosk/components/CloseDialogButton";
import usePrintOptionOrderArticle from "./usePrintOptionOrderArticle";
import OptionOrderGroupsSwiper from "./OptionOrderGroupsSwiper.tsx";
import CurrencySelector from "../../../global/components/CurrencySelector/CurrencySelector";
import LocalPriceSalesPrice from "./Prices/LocalPriceSalesPrice";
import Stock from "./Stock";
import ArticleImages from "./ArticleImages";
import CampaignBanner from "../MenuPage/CampaignBanner";
import useOrderArticlesFromShoppingCart from "../../../global/utils/order/useOrderArticlesFromShoppingCart";
import useMenuTagsForArticle from "../../../global/utils/hooks/useMenuTagsForArticle";
import MenuTagView from "../../../qr/components/MenuTagView";
import BackDialogButton from "../../components/BackDialogButton";
import { useActiveMenuTagsOfOrderArticle } from "../../../global/utils/menu/useActiveMenuTags";
import ActiveMenuTag from "../../../qr/components/ActiveMenuTag";
import { TransitionGroup } from "react-transition-group";
import { useOptionTranslations } from "../../../global/utils/translations/useOptionTranslations";
import _ from "lodash";
import { useAppSelector } from "../../../global/utils/redux/store";
import { selectArticleCampaignsMap } from "../../../global/utils/redux/selectors/selectArticleCampaignsMap";
import { selectCampaignsMap } from "../../../global/utils/redux/selectors/selectCampaignsMap";
import { selectAppLanguage } from "../../components/LanguageSelector/useLanguage.ts";
import BackgroundMediaUrlFromSalesarea from "../../hooks/useBackgroundMediaUrl.tsx";
import OptionOrderGroupsVerticalScrolling from "./OptionOrderGroupsVerticalScrolling.tsx";

interface Props {
  orderArticle: OrderArticle;
  isPiggyReward?: true;
  modifyingOrderArticle?: OrderArticle;
  fixedCount?: number;
  onOrderArticleOk?: (orderArticle: OrderArticle) => void;
  callAfterAdding?: () => void;
}

export default function OrderArticleSelectorPageContent(props: Props) {
  const lang = useAppSelector(selectAppLanguage);
  const box = useRef<any>(null);
  const grid = useRef(null);
  const parseCurrency = useCurrency({ location: CurrencyLocation.ArticleDetail });
  const menuTags = useMenuTagsForArticle(props.orderArticle.article);
  const selectedOrderArticles = useOrderArticlesFromShoppingCart(props.orderArticle);
  const { translate } = useOptionTranslations();
  const printOptionOrderArticle = usePrintOptionOrderArticle("JS-OrderArticleSelectorPage-Option");
  const articleCampaignsMap = useAppSelector(selectArticleCampaignsMap);
  const campaignsMap = useAppSelector(selectCampaignsMap);
  const orderArticle = props.orderArticle;
  const article = props.orderArticle.article;
  const activeMenuTags = useActiveMenuTagsOfOrderArticle(orderArticle);
  const [optionOrderGroupPageIndex, setOptionOrderGroupPageIndex] = useState(0);
  const wheelchairFriendlyEnabled = useAppSelector((state) => state.kiosk.wheelchairFriendly);
  const WHAT_IS_CONSIDERED_MANY_OPTION_ITEMS =
    useAppSelector((state) => state.global.salesarea?.compact_layout_from_x_number_of_options) ?? 8;
  const campaigns = useMemo(() => {
    if (article) {
      return (
        articleCampaignsMap[article.id]
          ?.map((campaignId) => {
            return campaignsMap[campaignId];
          })
          .filter((campaign) => campaign) ?? []
      );
    } else {
      return [];
    }
  }, [articleCampaignsMap, campaignsMap, article]);

  const maxNumberOfOptionItemsPerGroup = useMemo(() => {
    let maxNumberOfOptionItemsPerGroup = 0;

    function findMax(orderArticle: OrderArticle) {
      orderArticle.orderOptionGroups.forEach((orderOptionGroup) => {
        maxNumberOfOptionItemsPerGroup = Math.max(
          maxNumberOfOptionItemsPerGroup,
          orderOptionGroup.orderArticles.length
        );

        orderOptionGroup.orderArticles.forEach((orderArticle) => {
          findMax(orderArticle);
        });
      });
      return;
    }

    if (orderArticle) {
      findMax(orderArticle);
    }

    return maxNumberOfOptionItemsPerGroup;
  }, [orderArticle]);

  const compactLayout =
    maxNumberOfOptionItemsPerGroup > WHAT_IS_CONSIDERED_MANY_OPTION_ITEMS || wheelchairFriendlyEnabled;
  const spaciousLayout = maxNumberOfOptionItemsPerGroup === 0;

  const price =
    orderArticle.article.customData?.local_price != null ? (
      <LocalPriceSalesPrice localPrice={orderArticle.article.customData?.local_price} orderArticle={orderArticle} />
    ) : (
      <Box sx={{ paddingX: 4, display: "flex", fontStyle: "italic" }}>
        {parseCurrency(getTotalPrice(orderArticle), { name: "OrderArticleSelectorPage-price" })}
        <CurrencySelector />
        <Stock article={orderArticle.article} />
      </Box>
    );

  const orderArticleSelectorPageStyle =
    useAppSelector((state) => state.global.salesarea?.custom_data?.kiosk?.order_article_page_selector_style) ??
    "swiper";

  const articleDescription = getArticleDescription(article, lang, true);
  const hasArticleDescriptionWithContent = useMemo(() => {
    return (articleDescription ?? "").replace(/<\/[^>]+(>|$)/g, "").length > 0;
  }, [articleDescription]);

  return (
    <BackgroundMediaUrlFromSalesarea
      keyName={"extra_settings__kioskv5_background_of_order_article_selector_page"}
      className={"JS-OrderArticleSelectorPage-Root"}
      ref={box}
      sx={{
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 4,
        paddingBottom: wheelchairFriendlyEnabled ? null : 4,
        zoom: 0.9,
      }}
    >
      <BackDialogButton />
      {props.isPiggyReward ? null : <CloseDialogButton />}
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: 1,
          width: 1,
        }}
      >
        <Box sx={{ display: "inline-flex", width: 0.7, marginBottom: 1 }}>
          <Typography
            variant={"h5"}
            sx={{ fontWeight: 800, textAlign: "center", color: "primary.main", width: 1 }}
            className={"JS-ArticleDialog-Name"}
          >
            {getArticleName(article, lang)}
          </Typography>
        </Box>
        <Collapse in={selectedOrderArticles.length > 0 || (orderArticle.extraOrderArticles ?? []).length > 0}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              padding: "16px 48px 16px 48px",
              justifyContent: "center",
            }}
          >
            {orderArticle.extraOrderArticles?.map((orderArticle) => printOptionOrderArticle(orderArticle))}
            {selectedOrderArticles.map((orderArticle: OrderArticle) => (
              <Chip
                key={orderArticle.uuid}
                className={"JS-OrderArticleSelectorPage-Option-Chip"}
                sx={{
                  backgroundColor: "primary.light",
                  padding: 3,
                  marginX: 1,
                  marginBottom: 1,
                  color: "primary.contrastText",
                }}
                label={
                  orderArticle.count +
                  "x " +
                  (!_.isEmpty(orderArticle.article.translations)
                    ? getArticleName(orderArticle.article, lang)
                    : translate(orderArticle.article.name))
                }
              />
            ))}
          </Box>
        </Collapse>
        <Collapse
          in={article.articleTraits && Object.keys(article.articleTraits).length > 0 ? true : false}
          className={"JS-OrderArticleSelectorPage-ArticleTraits"}
        >
          <Box sx={{ display: "flex", flexWrap: "wrap", padding: 6 }}>
            {article.articleTraits
              ? Object.keys(article.articleTraits).map((traitTitle: string) => {
                  let title = article?.articleTraits?.[traitTitle]?.name;
                  if (article?.articleTraits?.[traitTitle]?.values) {
                    article?.articleTraits?.[traitTitle]?.values.forEach((value) => (title += ` - ${value}`));
                  }
                  return (
                    <Chip
                      key={traitTitle}
                      sx={{
                        backgroundColor: "primary.light",
                        padding: 3,
                        marginX: 1,
                        marginBottom: 1,
                        color: "white",
                      }}
                      label={title}
                    />
                  );
                })
              : null}
          </Box>
        </Collapse>
        {hasArticleDescriptionWithContent ? (
          <Collapse
            in={Boolean(!compactLayout || (compactLayout && optionOrderGroupPageIndex === 0))}
            sx={{ width: 1 }}
          >
            <Stack direction={"row"} sx={{ width: 1 }} alignItems={"center"} justifyContent={"center"}>
              {wheelchairFriendlyEnabled ? null : (
                <ArticleImages
                  article={article}
                  bigger={spaciousLayout}
                  sxWrapper={{ flex: "1 1 0%", width: 0, maxWidth: 0.4 }}
                />
              )}

              <Stack sx={{ maxWidth: 0.6 }}>
                <Typography
                  sx={{
                    opacity: 0.6,
                    width: 1,
                    fontStyle: "italic",
                    marginTop: 3,
                    paddingX: 4,
                    fontSize: "1.6rem",
                    textAlign: "left",
                  }}
                  className={"JS-ArticleDialog-Description"}
                  dangerouslySetInnerHTML={{ __html: articleDescription }}
                />

                <Box sx={{ fontSize: "1em", marginY: 3 }} className={"JS-ArticleDialog-Price"}>
                  {price}
                </Box>
              </Stack>
            </Stack>
          </Collapse>
        ) : (
          <>
            {wheelchairFriendlyEnabled ? null : (
              <ArticleImages article={article} bigger={spaciousLayout} sxWrapper={{ width: 1 }} />
            )}
          </>
        )}
        {compactLayout || hasArticleDescriptionWithContent ? null : (
          <Box sx={{ paddingX: 4, fontSize: "1em", marginY: 3 }} className={"JS-ArticleDialog-Price"}>
            {price}
          </Box>
        )}
        {campaigns[0] ? (
          <CampaignBanner
            campaign={campaigns[0]}
            sx={{
              left: 10,
              top: 200,
              right: "unset",
              width: 0.25,
              objectPosition: "top",
              height: "auto",
              position: "unset",
            }}
          />
        ) : null}
        <Collapse
          in={
            Boolean(!compactLayout || (compactLayout && optionOrderGroupPageIndex === 0)) && menuTags?.length > 0
              ? true
              : false
          }
          unmountOnExit
        >
          <Box sx={{ display: "flex", marginTop: 2, flexWrap: "wrap" }}>
            {menuTags.map((tag: MenuTag) => {
              return <MenuTagView menuTag={tag} width={40} margin={"4px"} key={tag.id} />;
            })}
          </Box>
        </Collapse>
        <Collapse in={!compactLayout || (compactLayout && optionOrderGroupPageIndex === 0)} unmountOnExit>
          <TransitionGroup>
            {activeMenuTags.map((activeMenuTag) => (
              <Collapse key={activeMenuTag.id}>
                <Box sx={{ display: "flex", marginTop: 2, flexWrap: "wrap", justifyContent: "center" }}>
                  <ActiveMenuTag menuTag={activeMenuTag} />
                </Box>
              </Collapse>
            ))}
          </TransitionGroup>
        </Collapse>
        <Box sx={{ width: 1, flex: "1 1 0%", height: 0, marginTop: 2 }} ref={grid}>
          {orderArticleSelectorPageStyle == "swiper" ? (
            <OptionOrderGroupsSwiper
              orderArticle={orderArticle}
              compactLayout={compactLayout}
              price={price}
              modifyingOrderArticle={props.modifyingOrderArticle}
              optionOrderGroupsPageIndex={optionOrderGroupPageIndex}
              setOptionOprderGroupsPageIndex={setOptionOrderGroupPageIndex}
              fixedCount={props.fixedCount}
              onOrderArticleOk={props.onOrderArticleOk}
            />
          ) : orderArticleSelectorPageStyle == "vertical_scrolling" ? (
            <OptionOrderGroupsVerticalScrolling
              orderArticle={orderArticle}
              compactLayout={compactLayout}
              price={price}
              modifyingOrderArticle={props.modifyingOrderArticle}
              optionOrderGroupsPageIndex={optionOrderGroupPageIndex}
              setOptionOprderGroupsPageIndex={setOptionOrderGroupPageIndex}
              fixedCount={props.fixedCount}
              onOrderArticleOk={props.onOrderArticleOk}
            />
          ) : null}
        </Box>
      </Box>
    </BackgroundMediaUrlFromSalesarea>
  );
}
