import { useMemo, useState } from "react";
import Article from "../../../global/utils/models/menu/Article";
import Image from "../../../global/components/Image";
import { BoxProps, Button, DialogContent, IconButton, Stack } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import { MediaUrl } from "../../../global/utils/redux/api/menuDataApi.ts";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";

interface Props {
  article: Article;
  bigger?: boolean;
  sxWrapper: BoxProps["sx"];
}

export default function ArticleImages(props: Props) {
  const [index, setIndex] = useState(0);
  const { openDialog } = useDialog();

  const maxIndex = useMemo(() => {
    return props.article.imageMediaUrls?.length - 1;
  }, [props.article]);

  const items = useMemo(() => {
    return props.article.imageMediaUrls.map((imageMediaUrl) => {
      return (
        <SwiperSlide key={imageMediaUrl.id}>
          <Box key={imageMediaUrl.id}>
            <Image
              onClick={() => {
                openDialog({ children: <ImageDialog imageMediaUrl={imageMediaUrl} /> });
              }}
              key={imageMediaUrl.id}
              srcSet={imageMediaUrl.conversions?.responsive?.srcset}
              style={{ maxHeight: props.bigger ? 600 : 300, width: "100%", objectFit: "contain" }}
              className={"JS-OrderArticleSelectorPage-Image"}
            />
          </Box>
        </SwiperSlide>
      );
    });
  }, [openDialog, props.article.imageMediaUrls, props.bigger]);

  return (
    <Box sx={{ position: "relative", ...props.sxWrapper }}>
      <Swiper
        tabIndex={index}
        spaceBetween={20}
        slidesPerView={1}
        onActiveIndexChange={() => {}}
        onSlideChange={(swiper) => setIndex(swiper.activeIndex)}
        onSwiper={() => {}}
      >
        {index > 0 ? <PrevButton /> : null}
        {index < maxIndex ? <NextButton /> : null}
        {items}
      </Swiper>
    </Box>
  );
}

function PrevButton() {
  const swiper = useSwiper();
  return (
    <IconButton
      sx={{ position: "absolute", left: 30, top: "50%", transform: "translate(0,-50%)", zIndex: 1 }}
      onClick={() => {
        swiper.slidePrev();
      }}
    >
      <ArrowLeft fontSize={"large"} />
    </IconButton>
  );
}
function NextButton() {
  const swiper = useSwiper();
  return (
    <IconButton
      // ref={nextElRef}
      sx={{ position: "absolute", right: 30, top: "50%", transform: "translate(0,-50%)", zIndex: 1 }}
      onClick={() => {
        swiper.slideNext();
        // setIndex((prevState) => prevState + 1);
      }}
    >
      <ArrowRight fontSize={"large"} />
    </IconButton>
  );
}

function ImageDialog({ imageMediaUrl }: { imageMediaUrl: MediaUrl }) {
  const { closeDialog } = useDialog();
  return (
    <DialogContent sx={{ py: 12 }}>
      <Stack gap={3} alignItems={"center"}>
        <Image
          key={imageMediaUrl.id}
          srcSet={imageMediaUrl.conversions?.responsive?.srcset}
          style={{ height: 600, width: "100%", objectFit: "contain" }}
          className={"JS-OrderArticleSelectorPage-Image"}
        />
        <Button
          variant={"contained"}
          sx={{ maxWidth: 400, width: 1 }}
          onClick={() => {
            closeDialog();
          }}
        >
          <FormattedMessageJamezz id={"Close"} />
        </Button>
      </Stack>
    </DialogContent>
  );
}
