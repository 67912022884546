import { ReactNode, useEffect, useRef } from "react";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Article from "../../../global/utils/models/menu/Article";
import ArticleSuggestionItem from "./ArticleSuggestionItem";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import { Translations, upsell_translation_keys } from "../../../types/shared/language";
import { useSelectTranslations } from "../../../global/utils/useCustomTexts";
import { postAnalyticsEvent, postEcomAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics";
import { OrderArticleAnalytics } from "../../../global/utils/models/order/OrderArticle.ts";
import CloseDialogButton from "../../components/CloseDialogButton.tsx";
import ScrollShadowsKiosk from "../../components/ScrollShadowKiosk.tsx";
import _ from "lodash";
import { CSSProperties } from "@mui/material/styles/createMixins";

export interface ArticleSuggestionPageProps
  extends Pick<OrderArticleAnalytics, "upsellType" | "upsellSource" | "upsellId"> {
  articles: Article[];
  title?: string;
  text?: string;
  useAsDialog?: boolean;
  translations?: Translations<upsell_translation_keys>;
  onClose?: () => void;
}

export default function ArticleSuggestionPage({ useAsDialog = true, ...props }: ArticleSuggestionPageProps) {
  const { closeDialog } = useDialog();

  useEffect(() => {
    return () => {
      if (props.onClose) props.onClose();
    };
  });

  useEffect(() => {
    postEcomAnalyticsEvent("view_promotion", {
      creative_name: props.title,
      creative_slot: props.title,
      promotion_name: props.text,
      items: props.articles.map((article) => ({
        item_id: article.id,
        item_name: article.name,
        price: article.price,
        quantity: 1,
      })),
    });
  }, [props]); /// do NOT add defaultOrderArticle as dependency

  const translations = useSelectTranslations(props.translations);
  const title = translations["onceTitel"] ?? props.title ?? <FormattedMessageJamezz id="People also ordered..." />;
  const text = translations["onceText"] ?? props.text;
  const refObject = useRef<HTMLElement>(null);

  return (
    <Box
      data-cy="upsell-dialog"
      sx={{
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 4,
      }}
      className={"JS-ArticleSuggestionPage-Root"}
    >
      {useAsDialog ? <CloseDialogButton /> : null}

      <Typography
        variant={"h5"}
        sx={{ fontStyle: "italic", fontWeight: 800, textAlign: "center", minHeight: 120, px: 14 }}
        className={"JS-ArticleSuggestionPage-Title"}
      >
        {title}
      </Typography>
      {text ? (
        <Typography variant={"subtitle1"} className={"JS-ArticleSuggestionPage-Text"}>
          {text}
        </Typography>
      ) : null}
      <Box
        sx={{ width: 1, flex: "1 1 0%", height: 0, display: "flex", flexDirection: "column", justifyContent: "center" }}
      >
        <Box
          sx={{
            overflowY: "auto",
            width: 1,
          }}
          ref={refObject}
        >
          <ScrollShadowsKiosk containerRef={refObject}>
            {_.chunk(props.articles, 3).map((articles) => (
              <ArticleSuggestionPageGrid numberOfArticles={articles.length} totalNumberOfColumns={3}>
                {articles.map((article, columnIndex) => {
                  return (
                    <ArticleSuggestionItem
                      key={article.id}
                      upsell_text={props.text ?? ""}
                      upsell_title={props.title ?? ""}
                      article={article}
                      upsellType={props.upsellType}
                      useAsDialog={useAsDialog}
                      upsellSource={props.upsellSource}
                      upsellId={props.upsellId}
                      columnIndex={columnIndex}
                    />
                  );
                })}
              </ArticleSuggestionPageGrid>
            ))}
          </ScrollShadowsKiosk>
        </Box>
      </Box>
      {useAsDialog ? (
        <Button
          data-cy="upsell-no-thank-you-btn"
          className="JS-ArticleSuggestionPage-NoThanksButton"
          // variant={"outlined"}
          sx={{ marginY: 2, boxShadow: "0 1px 10px 0px rgba(0,0,0,0.2)" }}
          onClick={() => {
            postAnalyticsEvent({
              category: "ArticleSuggestionPage",
              action: "No thanks",
            });
            closeDialog();
          }}
        >
          <FormattedMessageJamezz id={"No thanks"} />
        </Button>
      ) : null}
    </Box>
  );
}

function ArticleSuggestionPageGrid({
  numberOfArticles,
  totalNumberOfColumns,
  children,
}: {
  numberOfArticles: number;
  children: ReactNode;
  totalNumberOfColumns: number;
}) {
  const numberOfColumns = numberOfArticles;
  const numberOfRows = 3;

  const width = Math.floor((1 / totalNumberOfColumns) * 100);
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${numberOfColumns}, calc(${width}% - 20px))`,
        gridTemplateRows: `repeat(${numberOfRows}, auto)`,
        columnGap: 4,
        rowGap: 2,
        justifyItems: "center",
        minHeight: 160,
        p: 1,
        width: 1,
        justifyContent: "center",
        boxSizing: "border-box",
      }}
    >
      {children}
    </Box>
  );
}

export function makeGridArea(
  columnIndex: number,
  startRowIndex: number,
  spanNumberRows: number = 1
): CSSProperties["gridArea"] {
  return `${startRowIndex + 1} / ${columnIndex + 1} / ${spanNumberRows + startRowIndex + 1} / ${columnIndex + 2}`;
}
