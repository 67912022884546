import { Box, Skeleton, Typography } from "@mui/material";
import SupergroupTabBar from "./SupergroupTabBar";
import ArticlegroupTabBar from "./ArticlegroupTabBar";
import { useMemo } from "react";
import ArticlegroupContent from "./ArticlegroupContent";
import Articlegroup from "../../../global/utils/models/menu/Articlegroup";
import { getArticlegroupsBySupergroupId } from "../../../global/utils/models/menu/Menu";
import { useAppSelector } from "../../../global/utils/redux/store";
import OnScrollContent from "./EventListeners/OnScrollContent";
import useArticlegroups from "../../../global/utils/hooks/useArticlegroups";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";

import "swiper/css/pagination";
import "swiper/css";
import { MWiseRewards } from "../../components/MWise/Rewards";

import { selectSelectedSupergroupId } from "../../../global/utils/redux/menuSlice";
import { selectSupergroupsMap } from "../../../global/utils/redux/selectors/selectSupergroupsMap";
import { selectArticlegroupsMap } from "../../../global/utils/redux/selectors/selectArticlegroupsMap";
import { selectArticlegroupChildrenMap } from "../../../global/utils/redux/selectors/selectArticlegroupChildrenMap";
import { ArrangementsLoadingState } from "../../../types/qr/arrangements.ts";
import ArrangementReloadButton from "../../components/Arrangements/ArrangementReloadButton.tsx";
import { AyceRuleResult, useCheckAyceRules } from "../../utils/arrangements/useCheckAyceRules.ts";
import { MenuContentMessage } from "./MenuContentMessage.tsx";
import JamezzShowOrderButton from "../../components/JamezzShowOrderButton/JamezzShowOrderButton.tsx";
import useIsWidescreen from "../../../global/utils/useIsWidescreen.ts";
import { selectArrangementLoadingState } from "../../../global/utils/redux/arrangements/selectArrangementLoadingState.tsx";

function MenuContent() {
  const supergroupsMap = useAppSelector(selectSupergroupsMap);
  const pincodeStatus = useAppSelector((state) => state.arrangements.ticket_pincode.status);
  const background = useAppSelector(
    (state) =>
      state.global.salesarea?.media_urls?.extra_settings__qrv5_menu_content_background?.[0]?.conversions?.optimized?.url
  );

  const ayceRules = useCheckAyceRules();

  const arrangementLoadingState = useAppSelector(selectArrangementLoadingState);

  const menuLoaded =
    useAppSelector((state) => state.menuData.menuLoaded) &&
    (arrangementLoadingState === ArrangementsLoadingState.LOADED ||
      arrangementLoadingState === ArrangementsLoadingState.ARRANGEMENT_MODULE_INACTIVE ||
      ayceRules === AyceRuleResult.PRE_DINING);

  const supergroups: Articlegroup[] = useMemo(
    () => Object.values(supergroupsMap).sort((a, b) => a.sortKey - b.sortKey),
    [supergroupsMap]
  );

  const selectedSupergroupId = useAppSelector(selectSelectedSupergroupId);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const articlegroupChildrenMap = useAppSelector(selectArticlegroupChildrenMap);
  const mwiseEnabled = useAppSelector((state) => state.global.salesarea.mwise.enabled);

  const articlegroups: Articlegroup[] = useArticlegroups(
    getArticlegroupsBySupergroupId(articlegroupsMap, articlegroupChildrenMap, selectedSupergroupId)
  );

  const renderUptoArticlegroupIndex = articlegroups.length;

  const menuTagPreferences = useAppSelector((state) => state.menuState.menuTagPreferences);

  const numberOfPreferencesActive = useMemo(() => {
    let numberOfPreferencesActive = 0;

    Object.values(menuTagPreferences).forEach((preference) => {
      if (preference.checked) {
        numberOfPreferencesActive += 1;
      }
    });
    return numberOfPreferencesActive;
  }, [menuTagPreferences]);

  const isWidescreen = useIsWidescreen();

  if (pincodeStatus != null) {
    return null;
  }
  return (
    <>
      {arrangementLoadingState === ArrangementsLoadingState.ERR_NO_ACTIVE_ARRANGEMENT &&
      ayceRules !== AyceRuleResult.PRE_DINING ? (
        <MenuContentMessage cy="ayce-no-arrangements-loaded-msg">
          <Typography>
            <FormattedMessageJamezz id="AYCE.error.heading.no_arrangement_loaded" />
          </Typography>
          <Typography>
            <FormattedMessageJamezz id="AYCE.error.ask-for-assistance" />
          </Typography>
          <ArrangementReloadButton>
            <FormattedMessageJamezz id="AYCE.button.reload-tablestate" />
          </ArrangementReloadButton>
        </MenuContentMessage>
      ) : arrangementLoadingState === ArrangementsLoadingState.ARRANGEMENT_FATAL_ERROR ? (
        <MenuContentMessage cy="arrangements-fatal-error">
          <Typography>
            <FormattedMessageJamezz id="AYCE.error.cannot-load-arrangements" />
          </Typography>
          <Typography>
            <FormattedMessageJamezz id="AYCE.error.ask-for-assistance" />
          </Typography>
        </MenuContentMessage>
      ) : (
        <>
          <Box sx={{ position: "sticky", top: 55, zIndex: 5, width: 1 }} className={"JS-MenuContent-TabBarWrapper"}>
            {!menuLoaded ? (
              <>
                <Box sx={{ mx: 2, display: "flex", justifyContent: "center" }}>
                  <Skeleton sx={{ width: 100, height: 60, mx: 2 }} />
                  <Skeleton sx={{ width: 100, height: 60, mx: 2 }} />
                </Box>
                <Box sx={{ mx: 2, display: "flex", justifyContent: "center" }}>
                  <Skeleton sx={{ width: 100, height: 60, mx: 2 }} />
                  <Skeleton sx={{ width: 100, height: 60, mx: 2 }} />
                  <Skeleton sx={{ width: 100, height: 60, mx: 2 }} />
                  <Skeleton sx={{ width: 100, height: 60, mx: 2 }} />
                </Box>
              </>
            ) : (
              <>
                <SupergroupTabBar supergroups={supergroups} />
                <ArticlegroupTabBar articlegroups={articlegroups} />
              </>
            )}
          </Box>
          {mwiseEnabled ? (
            <Box sx={{ paddingX: 1, width: "100%" }}>
              <MWiseRewards />
            </Box>
          ) : null}
          {!menuLoaded ? (
            <Box sx={{ px: 2, display: "flex", justifyContent: "center", flexDirection: "column" }}>
              <Skeleton sx={{ width: 1, height: 80, mb: 1 }} />
              <Skeleton sx={{ width: 1, height: 80, mb: 1 }} />
              <Skeleton sx={{ width: 1, height: 80, mb: 1 }} />
              <Skeleton sx={{ width: 1, height: 80, mb: 1 }} />
              <Skeleton sx={{ width: 1, height: 80, mb: 1 }} />
            </Box>
          ) : null}
          {menuLoaded && articlegroups.length === 0 ? (
            <MenuContentMessage cy="supergroup-message-empty">
              <Typography>
                <FormattedMessageJamezz
                  id="AYCE.pre-dining.nothing-on-menu"
                  values={{
                    supergroup: selectedSupergroupId,
                  }}
                />
              </Typography>
            </MenuContentMessage>
          ) : null}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background: background ? `url(${background})` : undefined,
              flex: "1 1 0%",
              justifyContent: "space-between",
            }}
            id={"menu-content"}
            className={"JS-MenuContent-Root"}
          >
            <OnScrollContent articlegroups={articlegroups} />

            {articlegroups.length === 0 && numberOfPreferencesActive > 0 ? (
              <MenuContentMessage>
                <FormattedMessageJamezz
                  id={"No articles found. Notice, you have filters activated in the `Allergens & Preferences` menu."}
                />
              </MenuContentMessage>
            ) : null}
            <Box
              sx={{ paddingX: 1, display: "flex", justifyContent: "center", flexDirection: "column" }}
              className={"JS-MenuContent-Content"}
            >
              {articlegroups
                .slice(0, renderUptoArticlegroupIndex + 1)
                .filter((a) => a.showInCategoryMenu)
                .map((articlegroup) => {
                  return <ArticlegroupContent articlegroup={articlegroup} key={articlegroup.id} />;
                })}
            </Box>
            {!isWidescreen && <JamezzShowOrderButton />}
          </Box>

          {/*<ViewOrderButton />*/}
        </>
      )}
    </>
  );
}
export default MenuContent;
